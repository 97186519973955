<template>
	<div />
</template>

<script>
import baseEdit from '@/library_vue/components/baseEdit';

export default {
	name: 'Starfinder1eeAdminEquipmentEditSnippet',
	extends: baseEdit
};
</script>

<style scoped>
</style>
